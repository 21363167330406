import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

import "./Referrals.module.css";

import providerPicTwo from "./../../assets/images/provider-pic-two.png";
import displayPicture from "./../../assets/images/dp.png";
import No_Preview_available from "./../../assets/images/No_preview_available.jpg";

import { toLocalDate, onImageError } from "../../helpers/utils";
import { ReferInfoStatusTypes } from "../../constants";
import { DirectDentistReferral } from "./DirectDentistReferral";

export default function Referral(props) {
  const {
    currentPatient,
    getPatientReferrals,
    getPatientsIncompleteReferralSuggestion,
    referrals,
    referralSuggestion,
    getReferredFromData,
    getReferredToData,
    isOnlineCsrAvailable,
    resetReferralInfo,
    getPatientsDirectDentistReferral,
    directReferral = null
  } = props;

  const isReferralSuggestionCompletionDisabled = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.OutsideReferralSuggestionCompletionEnabled
  );
  const shouldShowDirectDentistProviderInfo = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowDirectDentistProviderInfo
  );

  useEffect(() => {
    getPatientReferrals();
    getPatientsIncompleteReferralSuggestion();
    getPatientsDirectDentistReferral();
  }, [currentPatient]);

  function getStatusText(referral) {
    if (referral && referral.status != undefined) {
      if (referral.status == ReferInfoStatusTypes.Accepted) {
        return "Accepted";
      } else if (referral.status == ReferInfoStatusTypes.Declined) {
        return "Declined";
      } else if (referral.status == ReferInfoStatusTypes.None) {
        return "Pending";
      }
    }
  }

  function getStatusClass(referral) {
    if (referral && referral.status != undefined) {
      if (referral.status == ReferInfoStatusTypes.Accepted) {
        return "text-success";
      } else if (referral.status == ReferInfoStatusTypes.Declined) {
        return "text-danger";
      } else if (referral.status == ReferInfoStatusTypes.None) {
        return "text-gold";
      }
    }
  }

  function isEmailBtnAvailable(referral) {
    return (
      referral &&
      referral.status != undefined &&
      (referral.status == ReferInfoStatusTypes.Accepted ||
        referral.status == ReferInfoStatusTypes.None) &&
      referral.referralSuggestionId > 0
    );
  }

  function showReferalInformation(referral) {
    if (referral) {
      if (referral.referToId && referral.referToId > 0) {
        return (
          <div className="gth-pro-meta">
            <h4
              data-bs-toggle="modal"
              data-bs-target="#providerProfile"
              onClick={() => getReferredToData(referral)}
            >
              <strong>{referral.referedTo.fullName}</strong>
            </h4>
            <a className="d-block text-body text-decoration-none">
              {referral.email}
            </a>
            <a className="d-block text-decoration-none">
              <strong>{referral.phone}</strong>
            </a>
          </div>
        );
      } else if (
        referral.referToImportedProviderId &&
        referral.referToImportedProviderId > 0
      ) {
        return (
          <div className="gth-pro-meta">
            <h4>
              <strong>{referral.referedToImportedProvider.fullName}</strong>
            </h4>
            <a className="d-block text-body text-decoration-none">
              {referral.referedToImportedProvider.email}
            </a>
            <a className="d-block text-decoration-none">
              <strong>{referral.referedToImportedProvider.phone}</strong>
            </a>
          </div>
        );
      } else {
        return (
          <div className="gth-pro-meta">
            <h4>
              <strong>{referral.referedTo.fullName}</strong>
            </h4>
            <a className="d-block text-body text-decoration-none">
              {referral.email}
            </a>
            <a className="d-block text-decoration-none">
              <strong>{referral.phone}</strong>
            </a>
          </div>
        );
      }
    }
  }

  function showEmailButton(referral) {
    if (referral) {
      if (
        referral.referToImportedProviderId &&
        referral.referToImportedProviderId > 0
      ) {
        return (
          isEmailBtnAvailable(referral) &&
          referral.referedToImportedProvider.email && (
            <a
              href={"mailto:" + referral.referedToImportedProvider.email}
              className="btn btn-outline-primary btn-xs text-nowrap"
            >
              <i className="bi bi-envelope-fill"></i> Email
            </a>
          )
        );
      } else {
        return (
          isEmailBtnAvailable(referral) &&
          referral.email && (
            <a
              href={"mailto:" + referral.email}
              className="btn btn-outline-primary btn-xs text-nowrap"
            >
              <i className="bi bi-envelope-fill"></i> Email
            </a>
          )
        );
      }
    }
  }

  return (
    <section className="step-section py-4 py-md-5">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Referrals
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-12 offset-md-0 col-xl-10 offset-xl-1">
            <div className="gth-main-header mb-3">
              <h1>Referrals</h1>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              {!isReferralSuggestionCompletionDisabled &&
                referralSuggestion && (
                  <div
                    className="gth-referral-card mb-3"
                    style={{ background: "#E9F2F9", padding: "15px 30px" }}
                  >
                    {referralSuggestion.hasDeclinedreferrals ? (
                      <>
                        <h2 className="mb-2">
                          The provider selected is not available at this time.
                        </h2>
                        <h3 className="mb-2">
                          Please select a different provider.
                        </h3>
                      </>
                    ) : (
                      <h2 className="mb-2">
                        A referral has been recommended by your provider
                      </h2>
                    )}
                    <h3 className="mb-3">
                      Complete the referral in 2 simple steps
                    </h3>
                    <p className="gth-list-number">
                      <span className="d-inline-flex align-content-center justify-content-center rounded-circle me-2 bg-primary">
                        1
                      </span>
                      <strong>
                        Search and select your preferred provider using filters.
                      </strong>
                    </p>
                    <p className="gth-list-number">
                      <span className="d-inline-flex align-content-center justify-content-center rounded-circle me-2 bg-primary">
                        2
                      </span>
                      <strong>
                        Once selected, please use the provider contact
                        information to reach out and make an appointment.
                      </strong>
                    </p>
                    <a
                      href=""
                      className="btn btn-primary mb-3"
                      data-bs-toggle="modal"
                      data-bs-target="#refferal-modal"
                      onClick={resetReferralInfo}
                    >
                      Select your Provider
                    </a>
                    {isOnlineCsrAvailable ? (
                      <p>
                        <strong>
                          If you have any questions, our virtual assistant can
                          help!
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#chatModal"
                            className="text-decoration-none pe-none"
                          >
                            {" "}
                            (click on the chat icon to chat with a virtual
                            assistant)
                          </a>
                        </strong>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              {!shouldShowDirectDentistProviderInfo &&
              referrals &&
              Array.isArray(referrals) &&
              referrals.length > 0 ? (
                <>
                  <h3 className="mb-3">Referral History</h3>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-nowrap" style={{ width: "70px" }}>
                          Date
                        </th>
                        <th className="text-nowrap">Selected Referral</th>
                        <th className="text-nowrap">Referral Reason</th>
                        <th className="text-nowrap">Referral Status</th>
                        <th className="text-nowrap">Referred By</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referrals &&
                        Array.isArray(referrals) &&
                        referrals.length > 0 &&
                        referrals.map((referral, index) => {
                          return (
                            <tr className="gth-pending-status">
                              <td data-label="Date">
                                {toLocalDate(referral.createdOn)}
                              </td>
                              <td data-label="Selected Referral">
                                <div className="d-flex gth-pro-detail justify-content-end justify-content-lg-start">
                                  {showReferalInformation(referral)}
                                </div>
                              </td>
                              <td
                                className="text-break"
                                data-label="Referral Reason"
                              >
                                {referral?.reason}
                              </td>
                              <td data-label="Referral Status">
                                <span
                                  className={getStatusClass(referral)}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Your referral has been sent to the provider and is waiting on a response from the provider"
                                >
                                  <strong>{getStatusText(referral)}</strong>
                                </span>
                              </td>
                              <td data-label="Referred By">
                                <div
                                  className="d-flex gth-pro-detail align-items-center justify-content-end justify-content-lg-start"
                                  onClick={() => getReferredFromData(referral)}
                                >
                                  <div className="gth-pro-meta">
                                    <h4
                                      data-bs-toggle="modal"
                                      data-bs-target="#providerProfile"
                                    >
                                      <strong>
                                        {referral?.referedFrom?.fullName}
                                      </strong>
                                    </h4>
                                  </div>
                                </div>
                              </td>
                              <td data-label="Action">
                                {showEmailButton(referral)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              ) : shouldShowDirectDentistProviderInfo &&
                directReferral != null ? (
                <DirectDentistReferral directReferral={directReferral} />
              ) : (
                <div className="d-flex justify-content-center mt-5 mb-5">
                  <h2>No referral data found!</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
