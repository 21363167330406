import React from "react";
import SmartScanSelfieCapture from "../SmartScanSelfieCapture/SmartScanSelfieCapture";
import { smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import {
  getSmartScanImageBasedOnCaptureOption,
  getSmartScanInstructionsBasedOnCaptureOption
} from "../../helpers/smartScanUtils";

const SmartScanUpperLipPage = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();

  return (
    <>
      <SmartScanSelfieCapture
        state={smartScanState}
        previousPagePath={"/smart-scan-lower-arch-confirm"}
        nextPagePath={
          smartScanState?.isAuthenticated
            ? "/smart-scan-upper-lip-confirm"
            : "/smart-scan-upper-lip-confirm-no-auth"
        }
        category={smartScanCategory.UpperLip}
        poseImage={getSmartScanImageBasedOnCaptureOption(
          smartScanState?.captureOption,
          smartScanCategory.UpperLip
        )}
        instruction={getSmartScanInstructionsBasedOnCaptureOption(
          smartScanState?.captureOption,
          smartScanCategory.UpperLip
        )}
      />
    </>
  );
};

export default SmartScanUpperLipPage;
