import React from "react";
import { useNavigate } from "react-router-dom";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";

const SmartScanDisclaimerPage = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();
  const navigate = useNavigate();

  function onIAgreeBtnClicked() {
    if (smartScanState && smartScanState.patientId > 0) {
      navigate("/smart-scan-photo-options", {
        state: {
          patientId: smartScanState?.patientId,
          isAuthenticated: true
        }
      });
    } else {
      navigate("/smart-scan-photo-options-no-auth", {
        state: {
          patientId: smartScanState?.patientId,
          isAuthenticated: false
        }
      });
    }
  }

  function goToPreviousPage() {
    navigate("/smart-scan-visit", {
      state: {
        ...smartScanState
      }
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="gth-main-header mb-3">
            <div className="heading-wrapper back-to-wrapper position-relative">
              <h1 className="title">Smart Scan Disclaimer </h1>
            </div>
          </div>
          <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
            <div className="smart-scan-fixed-button-bottom">
              <div className="border border-transparent disclamer-text pt-2 pt-md-4">
                <p>
                  THE SMARTSCAN PHOTO REVIEW AND WELLNESS SCORE AVAILABLE AT
                  DENTAL.COM IS NOT A DENTAL EXAM OR A DENTAL DIAGNOSIS, AND IT
                  IS NOT A SUBSTITUE FOR DENTAL ADVICE. THE WELLNESS SCORE IS
                  BASED SOLELY ON AN INTERPRETATION OF A LIMITED NUMBER OF
                  FEATURES THAT MAY BE VISIBLE ON THE PHOTOS THAT YOU SUBMIT.
                  THE QUALITY OF PHOTOS SUBMITTED VARIES, AND MANY INDICATORS OF
                  DENTAL DISEASE ARE NOT VISIBLE OR MAY BE MISSED OR
                  MISINTERPRETED REGARDLESS OF PHOTO QUALITY. ACCORDINGLY, WHILE
                  YOUR WELLNESS SCORE AND ANY RELATED OBSERVATIONS OR
                  SUGGESTIONS ARE INTENDED TO EDUCATE AND INFORM, THEY ARE NOT
                  DENTAL ADVICE AND ARE NOT TO BE USED AS A SUBSTITUTE FOR A
                  DENTAL EXAM BY A LICENSED DENTIST.
                </p>
                <p>
                  A DENTAL EXAM REQUIRES A LICENSED DENTIST. IT MAY INCLUDE, BUT
                  IS NOT LIMITIED TO, A VISUAL ORAL EXAM, INTERPRETATION OF
                  DENTAL X-RAYS AND INTRA-ORAL PICTURES TAKEN AT A DENTAL
                  FACILITY, AN ASSESSMENT OF YOUR BITE AND ORTHODONTIC STATUS,
                  AN ASSESSMENT OF HEAD AND NECK SOFT TISSUES, INCLUDING TONGUE,
                  LIPS, CHEEKS, NECK AND LYMPH NODES, AN ASSESSMENT OF YOUR
                  TEMPORO MANDIBULAR JOINT, AN ORAL CANCER SCREENING, AND A
                  DISCUSSION BETWEEN YOU AND THE DENTIST OF ANY CHANGES YOU HAVE
                  NOTICED OR QUESTIONS THAT YOU HAVE.
                </p>
                <p>
                  THE SMARTSCAN REVIEW DOES NOT LOOK FOR ABNORMLITIES ASSOCIATED
                  WITH ORAL CANCER. SOME OTHER ORAL DISEASE INDICATORS NOT
                  VISIBLE TO THE EYE OR FROM A PHOTO, AND NOT INCLUDED ON THE
                  SMARTSCAN REVIEW INCLUDE, BUT ARE NOT LIMITED TO: BONE LOSS,
                  STATUS OF BONE CREST, POCKET DEPTHS, SUBGINGIVAL CALCULUS,
                  STATUS OF THE PERIODONTAL LIGAMENT, INCIPIENT DECAY, DECAY
                  BETWEEN TEETH, SMALL ENAMEL FRACTURES, ABNORMALITIES
                  ASSOCIATED WITH THE ROOTS AND NERVES, ABSCESSES, ROOT
                  FRACTURES, FULLY IMPACTED TEETH, CYSTS AND TUMORS WITHIN THE
                  JAWBONE, TEMPORO MANDIBULAR JOINT PROBLEMS, MUSCULO SKELETAL
                  ABNORMALITIES, ABNORMALITIES UNDER THE TONGUE, SMALL SOFT
                  TISSUE ABNORMALITIES IN THE LIPS AND CHEEKS THAT REQUIRE
                  PALPATION FOR DISCOVERY.
                </p>
                <p>
                  BY PARTICIPATING IN THE SMARTSCAN PHOTO REVIEW PROCESS AND
                  RECEIVING A WELLNESS SCORE FROM DENTAL.COM, YOU UNDERSTAND,
                  ACKNOWLEDGE AND AGREE THAT:
                  <div>
                    <ol type="i">
                      <li>
                        ALL CONTENT ON THE DENTAL.COM SITE, INCLUDING THE
                        SMARTSCAN AND WELLNESS SCORE FEATURES, ARE INTENDED TO
                        EDUCATE AND INFORM, BUT ARE NOT DENTAL ADVICE AND ARE
                        NOT TO BE USED AS A SUBSTITUTE FOR A DENTAL EXAM BY A
                        LICENSED DENTIST;
                      </li>
                      <li>
                        THE USE OF PHOTOS IN THE REVIEW AND ASSESSMENT OF ORAL
                        DISEASE IS LIMITED, EVOLVING, AND WILL TAKE TIME TO
                        FULLY DEVELOP;
                      </li>
                      <li>
                        PARTICIPATION IN THE SMART SCAN PROCESS AND RECEIPT OF
                        THE WELLNESS SCORE AND ANY RESULTS OR SUGGESTIONS
                        RELATED THERETO IS DONE OF YOUR OWN FREE WILL AND IN
                        ACKNOWLEDGEMENT OF THE ASSOCIATED RISKS
                      </li>
                      <li>
                        YOUR RELIANCE ON ANY SCORE, OBSERVATION, GUIDANCE OR
                        SUGGESTION PROVIDED BY OR THROUGH DENTAL.COM IS SOLELY
                        AT YOUR OWN RISK; AND
                      </li>
                      <li>
                        DENTAL.COM AND VIRTUAL DENTAL CARE, INC. AND ITS
                        AFFILIATES ARE NOT RESPONSIBLE OR LIABLE FOR ANY
                        CONTENT, OBSERVATIONS, SCORE, SUGGESTION OR OTHER
                        INFORMATION PROVIDED THROUGH THIS SITE, NOR ANY RELIANCE
                        THEREON BY YOU.
                      </li>
                    </ol>
                  </div>
                </p>
                <small
                  className="text-muted my-3 my-md-4"
                  style={{ fontSize: ".875em" }}
                >
                  <em>
                    IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, CALL A DOCTOR
                    OR 911 IMMEDIATELY.
                  </em>
                </small>
              </div>
              <div className="btn-div">
                <button
                  onClick={onIAgreeBtnClicked}
                  disabled={!smartScanState?.patientId}
                  className="btn btn-secondary btn-rounded btn-lg px-4 px-md-5 mt-3 mt-md-0 w-100 w-md-auto"
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartScanDisclaimerPage;
