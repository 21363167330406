import { submitVerificationCode_Ajax } from "../../helpers/requests";

export default function useSubmitVerificationCode() {
  const submitVerificationCode = (
    phoneNumber,
    code,
    successCallback,
    errorCallback
  ) => {
    const requestVM = new FormData();
    requestVM.append("phone", phoneNumber);
    requestVM.append("code", code);

    submitVerificationCode_Ajax(
      requestVM,
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
  };

  return {
    submitVerificationCode
  };
}
