import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import NoPreviewAvailble from "./../../assets/images/No_preview_available.jpg";
import {
  GenderOptions,
  Roles,
  SignUpFormFields,
  ValidationTypes,
  AddDependentSuccessMessage,
  InsuranceCaptureOptionValues,
  InsuranceFormFields
} from "../../constants";
import {
  saveInsurance_Ajax,
  savePatientProfileSettings_Ajax,
  getCountries_Ajax,
  getStates_Ajax,
  getCityOrZips_Ajax,
  getTimezones_Ajax,
  getPatientProfileSettings_Ajax
} from "../../helpers/requests";
import { getPatientInfo } from "../../helpers/authManager";
import {
  validateFileMaxSize,
  handleApiErrors,
  isImageFile,
  isPhoneNumberValid,
  showAlertDialouge,
  isPatientDateOfBirthValid,
  onImageError
} from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { setIsFamilyMembersListChanged } from "../../redux/actions/userData";
import InsuranceFormUpdate from "../../components/InsuranceInformation/InsuranceFormUpdate";
import DateInputMask from "../DateInput/DateInputMask";
import { getPatientOrigin } from "../../ApplicationSettings";

const Tabs = {
  CreateProfile: 1,
  AddInsurance: 2
};

const MyDependentsAddMember = (props) => {
  const { toggleAddFamilyMemberFormVisibility, isAddFamilyMemberFormVisible } =
    props;
  const [activeTab, setActiveTab] = useState(1);
  const [isProfileCreated, setProfileCreated] = useState(0);
  const [createdProfilePatietId, setCreatedProfilePatietId] = useState(0);
  const [patientDataToSave, setPatientDataToSave] = useState(null);
  const dispatch = useDispatch();

  const handleTabClick = (tabNumber) => {
    if (tabNumber == 2 && isProfileCreated == 0) {
      return;
    }
    setActiveTab(tabNumber);
  };
  const shouldShowInsuranceInformation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowInsuranceInformation
  );

  const handleProfileCreation = (patId) => {
    setCreatedProfilePatietId(patId);
    setProfileCreated(Tabs.CreateProfile);
    toggleAddFamilyMemberFormVisibility();
  };

  const handleInsuranceSubmit = (insuranceData) => {
    if (insuranceData && patientDataToSave) {
      let { dependentData, profilePic } = { ...patientDataToSave };

      dependentData.insuranceInfo = insuranceData;

      let formData = new FormData();
      formData.append("patientVM", JSON.stringify(dependentData));

      if (profilePic?.file) {
        formData.append("profilePhoto", profilePic.file);
      }

      dispatch(showLoadingSpinner());
      savePatientProfileSettings_Ajax(
        formData,
        function (response) {
          if (response?.success) {
            dispatch(hideLoadingSpinner());
            showAlertDialouge("Message", AddDependentSuccessMessage);
            dispatch(setIsFamilyMembersListChanged(true));
            toggleAddFamilyMemberFormVisibility();
            setActiveTab(Tabs.CreateProfile);
            setPatientDataToSave(null);
          } else if (!response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  };

  function handleInsuranceFormBackButton() {
    if (activeTab == Tabs.AddInsurance) {
      setActiveTab(Tabs.CreateProfile);
    }
  }

  return (
    <>
      {/* <!-- Section - 2 --> */}
      {isAddFamilyMemberFormVisible ? (
        <div className={"collapse  show  mb-3"} id="collapseExample">
          <div>
            <ul className="nav nav-tabs justify-content-center">
              <li className="nav-item col-6">
                <button
                  className={`tab-button rounded-t-l-1 rounded-b-l-1 ${
                    activeTab === Tabs.CreateProfile ? "tab-active" : ""
                  }`}
                >
                  Profile
                </button>
              </li>
              {shouldShowInsuranceInformation && (
                <li className="nav-item col-6">
                  <button
                    className={`tab-button rounded-b-r-1 rounded-t-r-1 ${
                      activeTab === Tabs.AddInsurance ? "tab-active" : ""
                    }`}
                  >
                    Insurance Info
                  </button>
                </li>
              )}
            </ul>
          </div>

          <div
            style={{ display: activeTab === Tabs.CreateProfile ? "" : "none" }}
          >
            <CreateProfile
              setPatientId={handleProfileCreation}
              setActiveTab={setActiveTab}
              setPatientDataToSave={setPatientDataToSave}
            />
          </div>

          {activeTab === Tabs.AddInsurance ? (
            <AddInsurance
              patientId={createdProfilePatietId}
              handleSubmitWhenInAddDependentFlow={handleInsuranceSubmit}
              handleBackButton={handleInsuranceFormBackButton}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function AddInsurance(props) {
  const { handleSubmitWhenInAddDependentFlow, handleBackButton } = props;

  return (
    <section className="account-section sign-in-section">
      <InsuranceFormUpdate
        showApplyToDependentsOption={false}
        shouldPrePopulateFormWithResposiblePartysInfo={true}
        isAddDependentFlow={true}
        handleSubmitWhenInAddDependentFlow={handleSubmitWhenInAddDependentFlow}
        handleBackButton={handleBackButton}
        focusFieldName={InsuranceFormFields.PatientRelation}
      />
    </section>
  );
}

function CreateProfile(props) {
  const { setPatientDataToSave, setActiveTab } = props;
  let patientInfo = getPatientInfo();
  const [isPatientPhoneNumberValid, setIsPatientPhoneNumberValid] =
    useState(false);
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const shouldShowInsuranceInformation = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowInsuranceInformation
  );

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isValid, errors },
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      insurance: shouldShowInsuranceInformation
        ? InsuranceCaptureOptionValues.Yes
        : InsuranceCaptureOptionValues.No
    }
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [zips, setZips] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  let prevCountry = useRef(SignUpFormFields.Country);
  let prevState = useRef(SignUpFormFields.State);
  let prevCity = useRef(SignUpFormFields.City);
  let prevZip = useRef(SignUpFormFields.Zip);

  const countryWatch = watch(SignUpFormFields.Country);
  const stateWatch = watch(SignUpFormFields.State);
  const cityWatch = watch(SignUpFormFields.City);
  const phoneWatch = watch(SignUpFormFields.Phone);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [existingPatientInfo, setExistingPatientInfo] = useState(null);
  const [addMemberInProgress, setAddMemberInProgress] = useState(false);

  useEffect(() => {
    getCountries();
    populateForm();
  }, []);

  useEffect(() => {
    let phone = getValues(SignUpFormFields.Phone);

    if (phone && isPhoneNumberValid(phone)) {
      setIsPatientPhoneNumberValid(true);
    } else {
      setIsPatientPhoneNumberValid(false);
    }
  }, [phoneWatch]);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFields.Country);

    if (countryCode) {
      getStates(countryCode);
      getTimeZones(countryCode);
    }
  }, [countryWatch]);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFields.Country);
    let stateCode = getValues(SignUpFormFields.State);

    if (countryCode && stateCode) {
      getCities(countryCode, stateCode);
    }
  }, [stateWatch]);

  useEffect(() => {
    let countryCode = getValues(SignUpFormFields.Country);
    let stateCode = getValues(SignUpFormFields.State);
    let city = getValues(SignUpFormFields.City);

    if (countryCode && stateCode) {
      getZips(countryCode, stateCode, city);
    }
  }, [cityWatch]);

  function getCountries() {
    return new Promise(function (resolve) {
      getCountries_Ajax(
        function (response) {
          if (response && response.success && response.data) {
            setValue(SignUpFormFields.Country, "");
            setValue(SignUpFormFields.State, "");
            setValue(SignUpFormFields.City, "");
            setValue(SignUpFormFields.Zip, "");
            setCountries(response.data);
            setValue(SignUpFormFields.Country, "US");
          }
          resolve(true);
        },
        function (err) {
          handleApiErrors(err);
          resolve(false);
        }
      );
    });
  }

  function getStates(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        getStates_Ajax(
          countryCode,
          function (response) {
            if (response && response.success && response.data) {
              setStates(response.data);
              if (prevCountry.current != getValues(SignUpFormFields.Country)) {
                setValue(SignUpFormFields.State, "");
                setValue(SignUpFormFields.City, "");
                setValue(SignUpFormFields.Zip, "");
              } else {
                setValue(SignUpFormFields.State, prevState.current);
              }
              prevState.current = getValues(SignUpFormFields.State);
              prevCity.current = getValues(SignUpFormFields.City);
              prevZip.current = getValues(SignUpFormFields.Zip);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getCities(countryCode, stateCode) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode) {
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          undefined,
          undefined,
          undefined,
          function (response) {
            if (response && response.success && response.data) {
              setCities(response.data);
              if (prevState.current != getValues(SignUpFormFields.State)) {
                setValue(SignUpFormFields.City, "");
                setValue(SignUpFormFields.Zip, "");
              } else {
                setValue(SignUpFormFields.City, prevCity.current);
              }
              prevCity.current = getValues(SignUpFormFields.City);
              prevZip.current = getValues(SignUpFormFields.Zip);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getZips(countryCode, stateCode, city) {
    return new Promise(function (resolve) {
      if (countryCode && stateCode && city) {
        getCityOrZips_Ajax(
          countryCode,
          stateCode,
          city,
          undefined,
          undefined,
          function (response) {
            if (response && response.success && response.data) {
              setZips(response.data);
              if (prevCity.current != getValues(SignUpFormFields.City)) {
                setValue(SignUpFormFields.Zip, "");
              } else {
                setValue(SignUpFormFields.Zip, prevZip.current);
              }
              prevZip.current = getValues(SignUpFormFields.Zip);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function getTimeZones(countryCode) {
    return new Promise(function (resolve) {
      if (countryCode) {
        getTimezones_Ajax(
          countryCode,
          function (response) {
            if (
              response &&
              response.success &&
              response.data &&
              Array.isArray(response.data)
            ) {
              setValue(SignUpFormFields.Timezone, "");
              setTimeZones(response.data);
            }
            resolve(true);
          },
          function (err) {
            handleApiErrors(err);
            resolve(false);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  function populateForm() {
    let patientId = getPatientInfo().patid;

    dispatch(showLoadingSpinner());
    getPatientProfileSettings_Ajax(
      patientId,
      function (response) {
        if (response && response.success && response.data) {
          dispatch(hideLoadingSpinner());
          let patientInfo = response.data;
          setExistingPatientInfo(patientInfo);
          prevCountry.current = patientInfo.country;
          prevState.current = patientInfo.state;
          prevCity.current = patientInfo.city;
          prevZip.current = patientInfo.zip;

          if (patientInfo.address1)
            setValue(SignUpFormFields.Address1, patientInfo.address1);
          if (patientInfo.address2)
            setValue(SignUpFormFields.Address2, patientInfo.address2);
          if (patientInfo.emergencyContactPhone)
            setValue(SignUpFormFields.Phone, patientInfo.emergencyContactPhone);
          if (patientInfo.state)
            setValue(SignUpFormFields.State, patientInfo.state);
          if (patientInfo.city)
            setValue(SignUpFormFields.City, patientInfo.city);
          if (patientInfo.zip) setValue(SignUpFormFields.Zip, patientInfo.zip);
          if (patientInfo.timezone)
            setValue(SignUpFormFields.Timezone, patientInfo.timezone);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function isSaveBtnVisible() {
    return isValid && isPatientPhoneNumberValid && !addMemberInProgress;
  }
  function getBtnVerbiage() {
    if (!shouldShowInsuranceInformation) {
      return "Save";
    }
    return "Next";
  }

  function validateImageMaxSize() {
    if (profilePic) {
      let arr = [profilePic.file];
      let fileSizeErrMsg = validateFileMaxSize(arr);

      if (fileSizeErrMsg) {
        showAlertDialouge("Error", fileSizeErrMsg);

        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  function updateSelectedPhoto(event) {
    if (event && event.target && event.target.files) {
      let file = event.target.files[0];
      let fileName = file.name.toLowerCase();

      if (isImageFile(fileName)) {
        let tempPath = URL.createObjectURL(file);

        let picObj = {
          url: tempPath,
          type: fileName.split(".").pop(),
          file: file
        };

        setProfilePic(picObj);
      } else {
        showAlertDialouge("Error", "File type not supported");
      }
    }
  }

  function onSubmit(formData) {
    if (!validateImageMaxSize()) {
      return;
    }

    if (!formData) {
      return;
    }

    let dependentData = {};
    dependentData.firstName = formData.firstName;
    dependentData.lastName = formData.lastName;
    dependentData.isRespPartyIncluded = true;
    dependentData.respPartyUserId = patientInfo.user.id;
    dependentData.pgId = patientInfo.pgid;
    dependentData.gender = formData.gender;
    dependentData.dateOfBirth = formData.dateOfBirth;
    dependentData.address1 = formData.address1;
    dependentData.address2 = formData.address2;
    dependentData.country = formData.country;
    dependentData.city = formData.city;
    dependentData.timezone = formData.timezone;
    dependentData.state = formData.state;
    dependentData.zip = formData.zip;
    dependentData.emergencyContactPhone = formData.phone;
    dependentData.activeOfficeId = officeId;
    dependentData.origin = getPatientOrigin();

    dependentData.newUser = {
      pgId: patientInfo.pgid,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      roleId: Roles.Patient,
      gender: formData.gender,
      country: formData.country,
      city: formData.city,
      timezone: formData.timezone,
      state: formData.state,
      zip: formData.zip
    };

    dependentData.respParty = {
      pgId: patientInfo.pgid,
      email: patientInfo.user.userId,
      phone: patientInfo.user.phone,
      firstName: patientInfo.firstName,
      lastName: patientInfo.lastName,
      relationToPatient: formData.relation
    };

    let dobValidityCheck = isPatientDateOfBirthValid(formData.dateOfBirth);

    if (!dobValidityCheck.valid) {
      let errorMessage = dobValidityCheck.errorMessage
        ? dobValidityCheck.errorMessage
        : "Please enter a valid date of birth.";

      showAlertDialouge("Error", errorMessage);
      return;
    }

    if (formData.insurance == InsuranceCaptureOptionValues.Yes) {
      setPatientDataToSave({
        formData: formData,
        dependentData: dependentData,
        profilePic: profilePic
      });
      setActiveTab(Tabs.AddInsurance);
    } else if (formData.insurance == InsuranceCaptureOptionValues.No) {
      let formData = new FormData();
      formData.append("patientVM", JSON.stringify(dependentData));

      if (profilePic?.file) {
        formData.append("profilePhoto", profilePic.file);
      }

      dispatch(showLoadingSpinner());
      setAddMemberInProgress(true);
      savePatientProfileSettings_Ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success) {
            reset();
            setProfilePic(null);
            populateForm();
            setValue(SignUpFormFields.Phone, "");
            setValue(SignUpFormFields.Country, "US");
            showAlertDialouge("Message", AddDependentSuccessMessage);
            props.setPatientId(response.data.patid);
            dispatch(setIsFamilyMembersListChanged(true));
          } else if (response && !response.success && response?.message) {
            showAlertDialouge("Error", response.message);
          }
          setAddMemberInProgress(false);
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          setAddMemberInProgress(false);
          handleApiErrors(err);
        }
      );
    }
  }

  return (
    <div>
      <div className="card card-body border-radius-xlg border-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="profile-setting-upload mt-4 mb-3 col-md-12 col-xl-6">
              <div className="d-flex profile_img">
                <div className="circle me-2 me-md-4">
                  {profilePic && profilePic.url ? (
                    <img
                      className="profile-pic"
                      src={profilePic.url}
                      onError={onImageError}
                    />
                  ) : photoUrl ? (
                    <img
                      className="profile-pic"
                      src={photoUrl}
                      onError={onImageError}
                    />
                  ) : (
                    <img className="profile-pic" src={NoPreviewAvailble} />
                  )}
                </div>
                <div className="upload-btn">
                  <div className="input-group mb-1">
                    <label
                      className="input-group-text d-table w-auto btn btn-primary"
                      htmlFor={"inputGroupFile"}
                    >
                      <i className="bi bi-upload me-1"></i>Upload
                    </label>
                    <input
                      type="file"
                      className="form-control d-none"
                      id={"inputGroupFile"}
                      onChange={updateSelectedPhoto}
                    />
                  </div>
                  <div className="text-muted text-start">
                    (File should be Smaller than 1MB)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="" className="row pt-4" style={{ display: "flex" }}>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                {...register(SignUpFormFields.FirstName, {
                  required: true,
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed."
                  }
                })}
              />
              {errors.firstName?.message && (
                <p className="help-block">{errors.firstName.message}</p>
              )}
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                {...register(SignUpFormFields.LastName, {
                  required: true,
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed."
                  }
                })}
              />
              {errors.lastName?.message && (
                <p className="help-block">{errors.lastName.message}</p>
              )}
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                Emergency Contact Phone Number (XXX)-XXX-XXXX
                <span className="text-danger">*</span>
              </label>
              <InputMask
                mask="(999)-999-9999"
                id="phoneMask1"
                type="text"
                name="phone number"
                className="form-control"
                placeholder="(XXX)-XXX-XXXX"
                {...register(SignUpFormFields.Phone, {
                  required: true,
                  min: 14
                })}
              />
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                Select Relation<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                name="relation"
                id="relation"
                {...register("relation", { required: true })}
              >
                <option value="" defaultValue>
                  Select Relation
                </option>
                <option value="Son">Son</option>
                <option value="Daughter">Daughter</option>
                <option value="Spouse">Spouse</option>
                <option value="Father">Father</option>
                <option value="Mother">Mother</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 d-flex flex-wrap align-items-center">
              <label className="d-inline-block mb-2 me-3 me-md-4 w-auto">
                Gender<span className="text-danger">*</span>
              </label>
              <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                <input
                  className="form-check-input"
                  type="radio"
                  name="anyAllergy"
                  id="genderMale"
                  value={GenderOptions.Male}
                  {...register(SignUpFormFields.Gender, { required: true })}
                />
                <label className="form-check-label fs-4" htmlFor="genderMale">
                  Male
                </label>
              </div>
              <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                <input
                  className="form-check-input"
                  type="radio"
                  name="anyAllergy"
                  id="genderFemale"
                  value={GenderOptions.Female}
                  {...register(SignUpFormFields.Gender, { required: true })}
                />
                <label className="form-check-label fs-4" htmlFor="genderFemale">
                  Female
                </label>
              </div>
              <div className="form-check mb-2 me-2 me-md-4 d-inline-block">
                <input
                  className="form-check-input"
                  type="radio"
                  name="anyAllergy"
                  id="genderOther"
                  value={GenderOptions.Other}
                  {...register(SignUpFormFields.Gender, { required: true })}
                />
                <label className="form-check-label fs-4" htmlFor="genderOther">
                  Other
                </label>
              </div>
            </div>
            <div className="form-group mb-3 col-md-6">
              <label className="form-label">
                Date of Birth<span className="text-danger">*</span>
              </label>
              <DateInputMask
                register={register}
                fieldName={SignUpFormFields.DateOfBirth}
                errors={errors}
                validationType={ValidationTypes.DateOfBirth}
              />
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Primary Address</label>
              <textarea
                className="form-control"
                rows="4"
                id="comment1"
                placeholder="Primary Address"
                name="text"
                {...register(SignUpFormFields.Address1, { required: false })}
              ></textarea>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">Secondary Address</label>
              <textarea
                className="form-control"
                rows="4"
                id="comment2"
                placeholder="Secondary Address"
                name="text"
                {...register(SignUpFormFields.Address2, { required: false })}
              ></textarea>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Country<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                disabled={true}
                {...register(SignUpFormFields.Country, { required: true })}
              >
                <option key={0} value="">
                  Select Country
                </option>
                {countries.map((country, index) => {
                  return (
                    <option key={index + 1} value={country?.value}>
                      {country?.countryName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                State<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.State, { required: true })}
              >
                <option key={0} value="">
                  Select States
                </option>
                {states.map((state, index) => {
                  return (
                    <option key={index + 1} value={state?.value}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                City<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.City, { required: true })}
              >
                <option key={0} value="">
                  Select City
                </option>
                {cities.map((city, index) => {
                  return (
                    <option key={index + 1} value={city}>
                      {city}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Zip Code<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.Zip, { required: true })}
              >
                <option key={0} value="">
                  Select Zip Code
                </option>
                {zips.map((zip, index) => {
                  return (
                    <option key={index + 1} value={zip}>
                      {zip}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mb-3 col-md-6 col-xl-6">
              <label className="form-label">
                Timezone<span className="text-danger">*</span>
              </label>
              <select
                className="form-select form-control"
                {...register(SignUpFormFields.Timezone, { required: true })}
              >
                <option key={0} value="">
                  Select Timezones
                </option>
                {timeZones.map((timezone, index) => {
                  return (
                    <option key={index + 1} value={timezone?.offset}>
                      {timezone?.timezoneName}
                    </option>
                  );
                })}
              </select>
            </div>
            {shouldShowInsuranceInformation && (
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="mb-3">
                    <div className="d-flex flex-wrap flex-sm-nowrap form-group justify-content-start">
                      <label className="fs-3 mb-1 me-1 w-sm-auto">
                        Do you have insurance?
                      </label>
                      <div className="d-inline-block form-check me-4 ms-sm-auto">
                        <input
                          className="form-check-input insurance"
                          type="radio"
                          name="insurance"
                          id="insurance-yes"
                          value="insurance-yes"
                          {...register(SignUpFormFields.Insurance, {
                            required: true
                          })}
                        />
                        <label
                          className="form-check-label fs-4"
                          htmlFor="insurance-yes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check d-inline-block">
                        <input
                          className="form-check-input insurance"
                          type="radio"
                          name="insurance"
                          id="insurance-no"
                          value="insurance-no"
                          {...register(SignUpFormFields.Insurance, {
                            required: true
                          })}
                        />
                        <label
                          className="form-check-label fs-4"
                          htmlFor="insurance-no"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <button
                disabled={!isSaveBtnVisible()}
                type="submit"
                className="btn btn-primary btn-rounded btn-sm px-5"
              >
                {getBtnVerbiage()}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default MyDependentsAddMember;
