import React from "react";
import PhoneNumberSignUpPage from "../../components/PhoneNumberSignUpPage/PhoneNumberSignUpPage";

const SmartScanSendResult = () => {
  return (
    <body>
      <section className="account-section sign-in-section py-4 py-md-5 v-middle">
        <PhoneNumberSignUpPage />
      </section>
    </body>
  );
};

export default SmartScanSendResult;
