import { getLogoUrl } from "../../ApplicationSettings";
import { DentalDotComPageUrl } from "../../constants";

const BrandLogo = ({ alignmentClass = "text-center" }) => {
  const logo = require("./../../assets/images/" + getLogoUrl());

  function getImageUrl() {
    if (window?.location?.search) {
      return DentalDotComPageUrl + window.location.search;
    }
    return DentalDotComPageUrl;
  }

  return (
    <div className="row">
      <div className={"col-sm-12 " + (alignmentClass ? alignmentClass : "")}>
        <a
          className="hover-element p-0 d-inline-block mx-auto section-brand mb-3 mb-md-4"
          href={getImageUrl()}
        >
          <img src={logo} alt="Logo" />
        </a>
      </div>
    </div>
  );
};

export default BrandLogo;
