import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { Shades } from "../../constants";
import { getImageBasedOnShade_Ajax } from "../../helpers/requests";
import ShadeOwlCarousel from "./ShadeOwlCarousel";
import Shade1Image from "../../assets/images/shade/shade_1_Manual_interaction_demo1.png";
import Shade2Image from "../../assets/images/shade/shade_2_Manual_interaction_demo1.png";
import Shade3Image from "../../assets/images/shade/shade_3_Manual_interaction_demo1.png";
import Shade4Image from "../../assets/images/shade/shade_4_Manual_interaction_demo1.png";
import Shade5Image from "../../assets/images/shade/shade_5_Manual_interaction_demo1.png";
import Shade6Image from "../../assets/images/shade/shade_6_Manual_interaction_demo1.png";
import Shade7Image from "../../assets/images/shade/shade_7_Manual_interaction_demo1.png";
import Shade8Image from "../../assets/images/shade/shade_8_Manual_interaction_demo1.png";
import Shade9Image from "../../assets/images/shade/shade_9_Manual_interaction_demo1.png";
import Shade10Image from "../../assets/images/shade/shade_10_Manual_interaction_demo1.png";
import { getWhitenessImage_Ajax } from "../../helpers/requestsExternal";
import { fetchBlob, handleApiErrors } from "../../helpers/utils";
import { getPatientInfo } from "../../helpers/authManager";

const ShadeSelector = (props) => {
  const { whitnessScoreReport, onSeeADentistNowButtonClicked } = props;
  const [currentShadeId, setCurrentShadeId] = useState(-1);
  const [currentShadeColor, setCurrentShadeColor] = useState("");
  const [currentShadeScore, setCurrentShadeScore] = useState("");
  const [whitnessReportShadeColor, setWhitnessReportShadeColor] = useState("");
  const isDemoShadeSelectorEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isDemoShadeSelectorEnabled
  );

  const patientInfo = getPatientInfo();

  const isDemoPatient = useSelector((state) =>
    state?.appSettings?.settingsData?.demoPatientIds?.includes(
      patientInfo?.patid
    )
  );
  const isOnDemandCallFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isOnDemandCallFeatureEnabled
  );

  const dispatch = useDispatch();
  const prevSelShadeColor = useRef();
  const [rightSideImageUrl, setRightSideImageUrl] = useState("");
  const [defaultRightSideImageUrl, setDefaultRightSideImageUrl] = useState("");
  const [leftSideImageUrl, setLeftSideImageUrl] = useState("");
  const rightSideImageFile = useRef(null);
  const [isFetchingWhitenessImage, setIsFetchingWhitenessImage] =
    useState(false);

  const onHandleShadeClick = (id) => {
    if (
      !isDemoPatient &&
      (isFetchingWhitenessImage ||
        !rightSideImageFile.current ||
        !whitnessScoreReport)
    ) {
      return;
    }

    setCurrentShadeId(id);
    setCurrentShadeColor(Shades[id].color);
    setCurrentShadeScore(Shades[id].score);
  };

  const getImageBasedOnShade = () => {
    dispatch(showLoadingSpinner());
    setIsFetchingWhitenessImage(true);
    let whitenessReportShade = Shades.find(
      (x) => x.score == whitnessScoreReport.shadeScore
    );

    if (currentShadeScore == whitenessReportShade.score) {
      setRightSideImageUrl(defaultRightSideImageUrl);
      setIsFetchingWhitenessImage(false);
      dispatch(hideLoadingSpinner());
      return;
    }

    let formData = new FormData();
    formData.append("image", rightSideImageFile.current);
    formData.append("shade", currentShadeScore);
    formData.append("scan_id", 1);

    getWhitenessImage_Ajax(
      formData,
      function (response) {
        if (response.image_base64) {
          fetchBlob(
            "data:image/jpeg;base64," + response.image_base64,
            function (blob) {
              let url = URL.createObjectURL(blob, {
                type: "image/jpeg"
              });
              setRightSideImageUrl(url);
            }
          );
        }
        setIsFetchingWhitenessImage(false);
        dispatch(hideLoadingSpinner());
      },
      function (error) {
        handleApiErrors(error);
        setIsFetchingWhitenessImage(false);
        dispatch(hideLoadingSpinner());
      }
    );
  };

  useEffect(() => {
    if (whitnessScoreReport) {
      let curShade = Shades.find(
        (x) => x.score == whitnessScoreReport.shadeScore
      );
      setCurrentShadeId(curShade?.id);
      setCurrentShadeColor(curShade?.color);
      setCurrentShadeScore(curShade?.score);
      setWhitnessReportShadeColor(curShade?.color);

      if (isDemoPatient) {
        setLeftSideImageUrl(whitnessScoreReport.photoId);
      } else {
        processShadeImage(whitnessScoreReport.photoId);
      }
    }
  }, [whitnessScoreReport]);

  function getDemoShadeImage() {
    let shadeScore = Shades.find((x) => x.color == currentShadeColor)?.score;

    switch (shadeScore) {
      case 1:
        return Shade1Image;
      case 2:
        return Shade2Image;
      case 3:
        return Shade3Image;
      case 4:
        return Shade4Image;
      case 5:
        return Shade5Image;
      case 6:
        return Shade6Image;
      case 7:
        return Shade7Image;
      case 8:
        return Shade8Image;
      case 9:
        return Shade9Image;
      case 10:
        return Shade10Image;
      default:
        return "";
    }
  }

  useEffect(() => {
    if (prevSelShadeColor.current != currentShadeColor && currentShadeColor) {
      if (!isDemoPatient) {
        getImageBasedOnShade();
      } else {
        setRightSideImageUrl(getDemoShadeImage());
      }
      prevSelShadeColor.current = currentShadeColor;
    }
  }, [currentShadeColor]);

  function processShadeImage(url) {
    const img = new Image();

    img.src = url;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      let { xMin, yMin, xMax, yMax } = whitnessScoreReport;

      let croppedImageCanvas = document.createElement("canvas");
      const croppedImageWidth = xMax - xMin;
      const croppedImageHeight = yMax - yMin;

      croppedImageCanvas.width = croppedImageWidth;
      croppedImageCanvas.height = croppedImageHeight;
      let context = croppedImageCanvas.getContext("2d");

      context.drawImage(
        img,
        xMin,
        yMin,
        croppedImageWidth,
        croppedImageHeight,
        0,
        0,
        croppedImageWidth,
        croppedImageHeight
      );

      const imgWidth = croppedImageWidth;
      const imgHeight = croppedImageHeight;

      let leftCanvas = document.createElement("canvas");
      leftCanvas.width = imgWidth / 2;
      leftCanvas.height = imgHeight;
      let leftCtx = leftCanvas.getContext("2d");

      leftCtx.drawImage(
        croppedImageCanvas,
        0,
        0,
        imgWidth / 2,
        imgHeight,
        0,
        0,
        imgWidth / 2,
        imgHeight
      );

      let imageUrl = leftCanvas.toDataURL("image/jpeg");

      setLeftSideImageUrl(imageUrl);

      let rightCanvas = document.createElement("canvas");
      rightCanvas.width = imgWidth / 2;
      rightCanvas.height = imgHeight;
      let rightCtx = rightCanvas.getContext("2d");

      rightCtx.drawImage(
        croppedImageCanvas,
        imgWidth / 2,
        0,
        imgWidth / 2,
        imgHeight,
        0,
        0,
        imgWidth / 2,
        imgHeight
      );

      imageUrl = rightCanvas.toDataURL("image/jpeg");
      setRightSideImageUrl(imageUrl);
      setDefaultRightSideImageUrl(imageUrl);

      rightCanvas.toBlob((blob) => {
        let file = new File([blob], "rightSideImage.jpeg", {
          type: "image/jpeg"
        });
        rightSideImageFile.current = file;
      }, "image/jpeg");
    };
  }

  return (
    <div className="col-12 mt-2 mt-md-4">
      <ShadeOwlCarousel
        currentShadeId={currentShadeId}
        shadeScore={whitnessScoreReport?.shadeScore}
        onHandleShadeClick={onHandleShadeClick}
      />
      <div className="row flex-row-reverse align-items-center mb-3 mt-3">
        <div className="col-md-5">
          <div className="row tooth-comparision mb-4 mb-md-0">
            <div className="col-6">
              <div className="broken-column">
                <figure className="mb-0">
                  <img
                    className="w-100 object-cover"
                    src={leftSideImageUrl}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </figure>
                <div
                  className="shade"
                  style={{
                    backgroundColor: whitnessReportShadeColor
                  }}
                >
                  Current Shade {whitnessScoreReport.shadeScore}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="broken-column item" style={{ display: "block" }}>
                <figure className="mb-0">
                  <img
                    className="w-100 object-cover"
                    src={rightSideImageUrl}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </figure>
                {currentShadeId >= 0 && (
                  <div
                    className="shade"
                    style={{ background: Shades[currentShadeId].color }}
                  >
                    Shade {Shades[currentShadeId].score}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 pe-md-5">
          <h3 className="text-muted11 lh-base mb-3 mb-md-4">
            Track your progress each month during your treatment until you reach
            your desired score.
          </h3>
          {isOnDemandCallFeatureEnabled && (
            <button
              className="btn btn-secondary mb-3"
              onClick={onSeeADentistNowButtonClicked}
            >
              See a Dentist Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShadeSelector;
