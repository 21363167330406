import { sendVerificationCode_Ajax } from "../../helpers/requests";
import { getPatientOrigin, getPgId } from "../../ApplicationSettings";

export default function useSendVerificationCode() {
  const sendVerificationCode = (
    phoneNumber,
    successCallback,
    errorCallback
  ) => {
    const pgId = getPgId();
    const origin = getPatientOrigin();

    const requestVM = new FormData();
    requestVM.append("phone", phoneNumber);
    requestVM.append("origin", origin);
    requestVM.append("pgId", pgId);

    sendVerificationCode_Ajax(
      requestVM,
      function (response) {
        if (successCallback) {
          successCallback(response);
        }
      },
      function (error) {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
  };

  return {
    sendVerificationCode
  };
}
