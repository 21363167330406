import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Patterns, Roles, SignUpFormFields } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientOrigin, getPgidFromUrl } from "../../ApplicationSettings";
import { savePgId, saveTokenData } from "../../redux/actions/userData";
import { storeTokenData } from "../../helpers/authManager";
import {
  checkIfUserExists_Ajax,
  registerUser_Ajax
} from "../../helpers/requests";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import BrandLogo from "../BrandLogo/BrandLogo";
import useSubmitSmartScan from "../CustomHooks/useSubmitSmartScan";

export default function FinishAccountSetupPage() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });

  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );
  const [isSignUpInProgress, setIsSignUpInProgress] = useState(false);
  const { submitSmartScan, isSavingInProgress: isSmartScanSavingIsProgress } =
    useSubmitSmartScan();

  function getSignInPageUrlQueryParams() {
    let params = {};
    if (state?.hasSmartScanProgress) {
      params.hasSmartScanProgress = true;
    }
    return params;
  }

  function checkIfUserWithEmailExists(email) {
    return new Promise((resolve) => {
      dispatch(showLoadingSpinner());
      let jsonData = {
        email: email
      };

      checkIfUserExists_Ajax(
        JSON.stringify(jsonData),
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success && response?.data) {
            if (response.data.userExists && response.data.loginURL) {
              showAlertDialouge(
                "Email already exists",
                <>
                  An account with the provided email already exists. Please{" "}
                  <a
                    href={
                      response.data.loginURL +
                      "?" +
                      $.param(getSignInPageUrlQueryParams())
                    }
                  >
                    sign in
                  </a>{" "}
                  or try with another email
                </>
              );
              resolve(false);
            } else if (!response.data.userExists) {
              resolve(true);
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
            resolve(false);
          }
        },
        function (error) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
          resolve(false);
        }
      );
    });
  }

  function onSubmit(formData) {
    setIsSignUpInProgress(true);
    checkIfUserWithEmailExists(formData.email).then((res) => {
      if (res) {
        let pgId = getPgidFromUrl();

        if (pgId && formData && officeId >= 0 && state.phoneNumber) {
          formData.roleId = Roles.Patient;
          formData.pgId = pgId;
          formData.origin = getPatientOrigin();
          formData.phone = state.phoneNumber;

          dispatch(showLoadingSpinner());
          registerUser_Ajax(
            JSON.stringify(formData),
            officeId,
            async function (response) {
              dispatch(hideLoadingSpinner());

              if (response?.success && response?.data?.authData) {
                let authData = response.data.authData;

                if (authData?.token && authData?.info.pgid) {
                  storeTokenData(authData.info.pgid, JSON.stringify(authData));
                  dispatch(savePgId(authData.info.pgid));
                } else if (authData?.token && authData?.user?.pgid) {
                  storeTokenData(authData.user.pgid, JSON.stringify(authData));
                  dispatch(savePgId(authData.user.pgid));
                }

                dispatch(saveTokenData(authData));

                if (state?.hasSmartScanProgress) {
                  submitSmartScan();
                } else {
                  navigate("/dashboard");
                }
              } else if (response && !response.success && response.message) {
                showAlertDialouge("Error", response.message);
              }
              setIsSignUpInProgress(false);
            },
            function (err) {
              handleApiErrors(err);
              dispatch(hideLoadingSpinner());
              setIsSignUpInProgress(false);
            }
          );
        }
      } else {
        setIsSignUpInProgress(false);
      }
    });
  }

  return (
    <div className="container">
      <BrandLogo />
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
          <form
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h2 className="text-center text-black fw-bold mb-4">
              Finish account setup
            </h2>
            <div className="form-group text-center mt-2 mt-md-4 col-sm-12">
              <div className="form-group text-start mb-3">
                <label className="form-label">
                  First Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="First Name"
                  className="form-control"
                  placeholder="First Name"
                  {...register(SignUpFormFields.FirstName, {
                    required: "This field is required.",
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                />
                {errors.firstName?.message ? (
                  <p className="help-block">{errors.firstName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group text-start mb-3">
                <label className="form-label">
                  Last Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Last Name"
                  className="form-control"
                  placeholder="Last Name"
                  {...register(SignUpFormFields.LastName, {
                    required: "This field is required.",
                    maxLength: {
                      value: 50,
                      message: "Maximum 50 characters are allowed."
                    }
                  })}
                />
                {errors.lastName?.message ? (
                  <p className="help-block">{errors.lastName.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group text-start mb-3">
                <label className="form-label">
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Email"
                  className="form-control"
                  placeholder="Email"
                  {...register(SignUpFormFields.Email, {
                    required: "This field is required.",
                    pattern: {
                      value: Patterns.Email,
                      message:
                        "Please enter a valid email address. (e.g. example@email.com)"
                    }
                  })}
                />
                {errors.email?.message ? (
                  <p className="help-block">{errors.email.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-secondary text-center btn-rounded btn-lg px-5"
                disabled={
                  !isValid || isSignUpInProgress || isSmartScanSavingIsProgress
                }
              >
                Finish
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
