import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { checkIfPhoneNumberAlreadyExists_Ajax } from "../../helpers/requests";
import { hideLoadingSpinner } from "../../redux/actions/loadingSpinner";
import { handleApiErrors } from "../../helpers/utils";
import { getPgId } from "../../ApplicationSettings";

function useCheckIfPhoneNumberExists() {
  const dispatch = useDispatch();

  const checkIfPhoneNumberAlreadyExists = useCallback(
    (signUpFormData, existingPatientInfo) => {
      return new Promise((resolve) => {
        let jsonData = {
          phoneNumber: signUpFormData.phone,
          userId: existingPatientInfo?.userId,
          pgId: getPgId()
        };

        checkIfPhoneNumberAlreadyExists_Ajax(
          JSON.stringify(jsonData),
          function (response) {
            dispatch(hideLoadingSpinner());
            if (response?.success && response?.data) {
              resolve(response.data?.userWithSamePhoneNoExists);
            }
          },
          function (err) {
            dispatch(hideLoadingSpinner());
            handleApiErrors(err);
          }
        );
      });
    },
    [dispatch]
  );

  return { checkIfPhoneNumberAlreadyExists };
}

export default useCheckIfPhoneNumberExists;
