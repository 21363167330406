import React, { useEffect, useRef, useState } from "react";
import {
  handleApiErrors,
  queryStringToJSON,
  showAlertDialouge,
  validatePhoneNumber
} from "../../helpers/utils";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { GuestPatientKey, SignUpFormFields } from "../../constants";
import BrandLogo from "../BrandLogo/BrandLogo";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubmitVerificationCodePage from "../SubmitVerificationCodePage/SubmitVerificationCodePage";
import { getPatientOrigin, getPgId } from "../../ApplicationSettings";
import { login_Ajax } from "../../helpers/requests";
import { useDispatch } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { savePgId, saveTokenData } from "../../redux/actions/userData";
import { storeTokenData } from "../../helpers/authManager";
import useSendVerificationCode from "../CustomHooks/useSendVerificationCode";
import {
  clearSmartScanSelfieCaptureProgress,
  getSmartScanSelfieCaptureProgress,
  saveSmartScanSelfieCaptureProgress
} from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";

const PhoneNumberLoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });

  const navigate = useNavigate();
  const [sentVerificationCode, setSentVerificationCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const [
    isVerificationCodeRequestInProgress,
    setIsVerificationCodeRequestInProgress
  ] = useState(false);
  const { sendVerificationCode } = useSendVerificationCode();
  const hasSmartScanProgress = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    checkForQueryParams();
    removeQueryParams();
  }, []);

  function checkForQueryParams() {
    let params = queryStringToJSON();

    if (params?.hassmartscanprogress) {
      hasSmartScanProgress.current = params.hassmartscanprogress;
    }
  }

  function removeQueryParams() {
    if (searchParams.get("hasSmartScanProgress")) {
      searchParams.delete("hasSmartScanProgress");
      setSearchParams(searchParams);
    }
  }

  function verfiyPhoneNumber(data) {
    if (data.phone) {
      dispatch(showLoadingSpinner());
      setIsVerificationCodeRequestInProgress(true);

      setPhoneNumber(data.phone);
      sendVerificationCode(
        data.phone,
        function (response) {
          dispatch(hideLoadingSpinner());
          setIsVerificationCodeRequestInProgress(false);
          if (response.success) {
            setPhoneNumber(data.phone);
            setSentVerificationCode(true);
            showAlertDialouge(
              "Message",
              "A verification code has been sent to the number " + phoneNumber
            );
          } else if (response.message) {
            showAlertDialouge("Error", response.message);
            setSentVerificationCode(false);
          }
        },
        function (error) {
          dispatch(hideLoadingSpinner());
          setIsVerificationCodeRequestInProgress(false);
          setSentVerificationCode(false);
          handleApiErrors(error);
        }
      );
    }
  }

  function signInUsingEmail() {
    navigate({
      pathname: "/login",
      search: window.location.search
    });
  }

  function isCurrentOriginPatient(data) {
    return (
      data?.dashboardURL &&
      data?.info.loginUrl &&
      data?.info.pgid &&
      data?.info.origin === getPatientOrigin()
    );
  }

  function saveTokenInfo(data) {
    if (data?.token && data?.info?.pgid) {
      storeTokenData(data.info.pgid, JSON.stringify(data));
      dispatch(savePgId(data.info.pgid));
    } else if (data?.token && data?.user?.pgid) {
      storeTokenData(data.user.pgid, JSON.stringify(data));
      dispatch(savePgId(data.user.pgid));
    }

    dispatch(saveTokenData(data));
  }

  async function processSmartScanData(patientId) {
    if (patientId > 0 && hasSmartScanProgress.current == "true") {
      let currentGuestUserData = await getSmartScanSelfieCaptureProgress(
        GuestPatientKey
      );

      await saveSmartScanSelfieCaptureProgress(
        {
          ...currentGuestUserData,
          patientId: patientId,
          isAuthenticated: true
        },
        patientId
      );

      await clearSmartScanSelfieCaptureProgress(GuestPatientKey);
    }
  }

  function submitVerificationCode(code) {
    if (phoneNumber && code) {
      let pgId = getPgId();

      let loginData = {
        pgId: pgId,
        phoneNumber: phoneNumber,
        code: code
      };

      dispatch(showLoadingSpinner());
      login_Ajax(
        JSON.stringify(loginData),
        async function (result) {
          dispatch(hideLoadingSpinner());
          if (result?.data?.token) {
            saveTokenInfo(result.data);

            if (!isCurrentOriginPatient(result.data)) {
              window.location.replace(result.data.dashboardURL);
            } else if (hasSmartScanProgress.current) {
              await processSmartScanData(result.data.info.patid);
              navigate("/smart-scan-pose", {
                state: {
                  patientId: result.data.info.patid,
                  isAuthenticated: true
                }
              });
            } else {
              navigate("/dashboard");
            }
          } else if (result?.message) {
            showAlertDialouge("Error", result.message);
          }
        },
        function (err) {
          handleApiErrors(err);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function resendVerificationCode() {
    if (phoneNumber) {
      dispatch(showLoadingSpinner());

      sendVerificationCode(
        phoneNumber,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response.success) {
            showAlertDialouge(
              "Message",
              "A verification code has been re-sent to the number " +
                phoneNumber
            );
          } else if (response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (error) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
        }
      );
    }
  }

  function onSignUpClicked() {
    navigate({
      pathname: "/sign-up-with-phone",
      search: window.location.search
    });
  }

  return (
    <div className="container">
      <BrandLogo />
      <div className="row">
        {!sentVerificationCode ? (
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
            <form
              onSubmit={handleSubmit(verfiyPhoneNumber)}
              className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            >
              <h2 className="text-center text-violet mb-4">
                Sign in to your account
              </h2>
              <div className="form-group mb-3">
                <InputMask
                  mask="(999)-999-9999"
                  className="form-control"
                  placeholder="Mobile Number"
                  {...register(SignUpFormFields.Phone, {
                    required: "This field is required.",
                    min: 14,
                    validate: (value) => validatePhoneNumber(value)
                  })}
                />
                {errors.phone?.message ? (
                  <p className="help-block">{errors.phone.message}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="text-center fs-4 mt-3">
                <span onClick={signInUsingEmail}>
                  <a className="text-primary"> Sign in</a>
                </span>{" "}
                using email and password instead
              </div>
              <div className="form-group text-center mt-3 mt-md-3">
                <button
                  disabled={isVerificationCodeRequestInProgress}
                  type="submit"
                  className="btn btn-secondary btn-rounded btn-lg px-5"
                >
                  {" "}
                  Sign In{" "}
                </button>
              </div>
            </form>
            <div className="text-center fs-3 mt-3">
              {" "}
              Don’t have an account?
              <span onClick={onSignUpClicked}>
                <a className="text-primary"> Sign Up </a>
              </span>
            </div>
          </div>
        ) : (
          <div className="col-12">
            <SubmitVerificationCodePage
              onSubmit={submitVerificationCode}
              phoneNumber={phoneNumber}
              onResend={resendVerificationCode}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneNumberLoginPage;
