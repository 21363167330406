import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Login.module.css";
import {
  Link,
  useNavigate,
  useSearchParams,
  createSearchParams
} from "react-router-dom";
import {
  loginPatientWithToken_Ajax,
  login_Ajax,
  updateUserSession_Ajax
} from "../../helpers/requests";
import {
  storeTokenData,
  getTokenData,
  updateTokenData,
  storeSelectedPatientId
} from "../../helpers/authManager";

import { saveTokenData, savePgId } from "../../redux/actions/userData";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  queryStringToJSON,
  showAlertDialouge,
  extractCustomLinkQueryParams,
  executeDentistDirectReferralProcess
} from "../../helpers/utils";
import moment from "moment";
import { useForm } from "react-hook-form";
import BrandLogo from "../BrandLogo/BrandLogo";
import { GuestPatientKey, SignInPageMetaDescription } from "../../constants";
import { getPatientOrigin, getPgidFromUrl } from "../../ApplicationSettings";
import {
  clearSmartScanSelfieCaptureProgress,
  getSmartScanSelfieCaptureProgress,
  saveSmartScanSelfieCaptureProgress
} from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: "onChange", defaultValues: { email: "", password: "" } });

  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const redirectTo = useRef();
  const token = useRef("");
  const apiKey = useRef("");
  const selectedPatientId = useRef();
  const smartScanId = useRef();
  const hasSmartScanProgress = useRef(false);
  const paramsWithoutDecode = extractCustomLinkQueryParams();

  const isAutoLoginRequired = !!apiKey.current && !!token.current;
  const shouldShowContent = !isAutoLoginRequired;

  const shouldAllowLogInViaPhoneNumber = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldAllowLogInViaPhoneNumber
  );

  const signUpButtonClicked = () => {
    let url = "/sign-up";
    const params = queryStringToJSON();

    navigate({
      pathname: url,
      search: createSearchParams(params).toString()
    });
  };

  useEffect(() => {
    checkForQueryParams();
    removeQueryParams();

    if (apiKey.current && token.current) {
      loginPatientWithToken();
    } else {
      checkForToken();
    }

    const prevTitle = document.title;
    document.title = "Dental.com | Sign In";

    const metaDescription = document.querySelector('meta[name="description"]');
    
    if (metaDescription) {
      metaDescription.setAttribute('content', SignInPageMetaDescription);
    }

    return () => {
      document.title = prevTitle;
    };
  }, []);

  function removeQueryParams() {
    if (searchParams.get("redirectTo")) {
      searchParams.delete("redirectTo");
      setSearchParams(searchParams);
    }

    if (searchParams.get("apiKey")) {
      searchParams.delete("apiKey");
      setSearchParams(searchParams);
    }

    if (searchParams.get("token")) {
      searchParams.delete("token");
      setSearchParams(searchParams);
    }

    if (searchParams.get("patientId")) {
      searchParams.delete("patientId");
      setSearchParams(searchParams);
    }

    if (searchParams.get("smartScanId")) {
      searchParams.delete("smartScanId");
      setSearchParams(searchParams);
    }

    if (searchParams.get("hasSmartScanProgress")) {
      searchParams.delete("hasSmartScanProgress");
      setSearchParams(searchParams);
    }
  }

  function checkForQueryParams() {
    let params = queryStringToJSON();

    if (params) {
      if (params.redirectto) {
        redirectTo.current = params.redirectto;
      }
      if (params.apikey) {
        apiKey.current = params.apikey;
      }
      if (params.token) {
        token.current = params.token;
      }
      if (params.patientid) {
        selectedPatientId.current = params.patientid;
      }
      if (params.smartscanid) {
        smartScanId.current = params.smartscanid;
      }
      if (params.hassmartscanprogress) {
        hasSmartScanProgress.current = params.hassmartscanprogress;
      }
    }
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  function checkForToken() {
    let tokenData = getTokenData();

    if (tokenData) {
      dispatch(saveTokenData(tokenData));

      if (tokenData.info.pgid) {
        dispatch(savePgId(tokenData.info?.pgid));
      } else if (tokenData.user.pgid) {
        dispatch(savePgId(tokenData.user?.pgid));
      }

      if (redirectTo.current) {
        navigate(redirectTo.current);
      } else {
        navigate("/dashboard");
      }
    } else {
      let token = searchParams.get("token");

      if (token != null) {
        let decodedToken = parseJwt(token);
        let expired = decodedToken.exp;

        tokenData = {
          token: token,
          expiration: new Date(expired).toUTCString()
        };

        storeTokenData(decodedToken.pgid, JSON.stringify(tokenData));
        dispatch(saveTokenData(tokenData));

        if (tokenData.info?.pgid) {
          dispatch(savePgId(tokenData.info?.pgid));
        } else if (tokenData.user?.pgid) {
          dispatch(savePgId(tokenData.user?.pgid));
        }

        navigate("/dashboard");
      }
    }
  }

  function toogleDisplayPasswordBtnClick() {
    setShowPassword(!showPassword);
  }

  function saveTokenInfo(data) {
    if (data?.token && data.info && data.info.pgid) {
      storeTokenData(data.info.pgid, JSON.stringify(data));
      dispatch(savePgId(data.info.pgid));
    } else if (data?.token && data.user && data.user.pgid) {
      storeTokenData(data.user.pgid, JSON.stringify(data));
      dispatch(savePgId(data.user.pgid));
    }

    dispatch(saveTokenData(data));
  }

  function isCurrentOriginPatient(data) {
    return (
      data?.dashboardURL &&
      data?.info &&
      data?.info.loginUrl &&
      data?.info.pgid &&
      data?.info.origin === getPatientOrigin()
    );
  }

  async function processSmartScanData(patientId) {
    if (patientId > 0 && hasSmartScanProgress.current == "true") {
      let currentGuestUserData = await getSmartScanSelfieCaptureProgress(
        GuestPatientKey
      );

      await saveSmartScanSelfieCaptureProgress(
        {
          ...currentGuestUserData,
          patientId: patientId,
          isAuthenticated: true
        },
        patientId
      );

      await clearSmartScanSelfieCaptureProgress(GuestPatientKey);
    }
  }

  function login(loginFormData) {
    if (loginFormData.email && loginFormData.password) {
      let pgId = getPgidFromUrl();
      let loginData = {
        pgId: pgId,
        userId: loginFormData.email,
        password: loginFormData.password
      };
      dispatch(showLoadingSpinner());
      login_Ajax(
        JSON.stringify(loginData),
        async function (result) {
          dispatch(hideLoadingSpinner());

          if (result?.data?.token) {
            saveTokenInfo(result.data);
            await executeDentistDirectReferralProcess(
              paramsWithoutDecode.linkType,
              paramsWithoutDecode.dataString,
              result.data.info?.patid
            );
            if (!isCurrentOriginPatient(result.data)) {
              window.location.replace(result.data.dashboardURL);
            } else if (redirectTo.current) {
              navigate(redirectTo.current);
            } else if (hasSmartScanProgress.current) {
              await processSmartScanData(result.data.info.patid);
              navigate("/smart-scan-pose", {
                state: {
                  patientId: result.data.info.patid,
                  isAuthenticated: true
                }
              });
            } else {
              navigate("/dashboard");
            }
          } else if (result?.message) {
            showAlertDialouge("Error", result.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function getRedirectedPageState() {
    let redirectedPageState = {};

    if (
      selectedPatientId.current > 0 &&
      smartScanId.current > 0 &&
      redirectTo.current == "/see-dentist"
    ) {
      redirectedPageState.patientId = selectedPatientId.current;
      redirectedPageState.smartScanId = smartScanId.current;
    }

    return redirectedPageState;
  }

  function loginPatientWithToken() {
    if (apiKey.current && token.current) {
      let tokenData = {
        token: token.current,
        expiration: moment().add(12, "hours")
      };

      updateTokenData(tokenData);

      dispatch(showLoadingSpinner());
      loginPatientWithToken_Ajax(
        apiKey.current,
        function (result) {
          dispatch(hideLoadingSpinner());
          if (result?.data?.token) {
            if (
              result.data.token &&
              result.data.info &&
              result.data.info.pgid
            ) {
              storeTokenData(
                result.data.info.pgid,
                JSON.stringify(result.data)
              );
              dispatch(savePgId(result.data.info.pgid));
            } else if (
              result.data.token &&
              result.data.user &&
              result.data.user.pgid
            ) {
              storeTokenData(
                result.data.user.pgid,
                JSON.stringify(result.data)
              );
              dispatch(savePgId(result.data.user.pgid));
            }

            dispatch(saveTokenData(result.data));
            updateUserSession_Ajax();

            if (selectedPatientId.current > 0) {
              storeSelectedPatientId(selectedPatientId.current);
            }

            if (redirectTo.current) {
              let redirectedPageState = getRedirectedPageState();

              navigate(redirectTo.current, {
                state: {
                  ...redirectedPageState
                }
              });
            } else {
              navigate("/dashboard");
            }
          } else if (result?.message) {
            showAlertDialouge("Error", result.message);
          }
        },
        function (error) {
          handleApiErrors(error);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function singInUsingPhoneNumber() {
    navigate({
      pathname: "/sign-in-with-phone",
      search: window.location.search
    });
  }

  function forgetPasswordClicked() {
    navigate({
      pathname: "/forgot-password",
      search: window.location.search
    });
  }

  return shouldShowContent ? (
    <div className="container">
      <BrandLogo />
      <div className="row">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
          <form
            onSubmit={handleSubmit(login)}
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
          >
            <h2 className="text-center text-violet mb-4">
              Sign in to your account
            </h2>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                {...register("email", { required: "Please enter email" })}
              />
              {errors.email?.message ? (
                <p className="help-block">{errors.email.message}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group mb-3">
              <div className="form-text fs-5 mb-1 show-password">
                {showPassword ? (
                  <a
                    onClick={() => {
                      toogleDisplayPasswordBtnClick();
                    }}
                    className="ms-auto d-table text-muted"
                  >
                    Hide Password
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      toogleDisplayPasswordBtnClick();
                    }}
                    className="ms-auto d-table text-muted"
                  >
                    Show Password
                  </a>
                )}
              </div>
              <input
                type={!showPassword ? "password" : "text"}
                className="form-control"
                placeholder="Password"
                {...register("password", {
                  required: "Please enter password"
                })}
              />
              {errors.password?.message ? (
                <p className="help-block">{errors.password.message}</p>
              ) : (
                <></>
              )}
              <div className="form-text fs-5">
                <span onClick={forgetPasswordClicked}>
                  <a className="text-muted fst-italic d-table ms-auto">
                    Forgot password?
                  </a>
                </span>
              </div>
              {shouldAllowLogInViaPhoneNumber ? (
                <div className="text-center fs-4 mt-3">
                  <span onClick={singInUsingPhoneNumber}>
                    <a className="text-primary"> Sign in</a>
                  </span>{" "}
                  using your phone number instead
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group text-center mt-3 mt-md-3">
              <button
                type="submit"
                className="btn btn-secondary btn-rounded btn-lg px-5"
              >
                {" "}
                Sign In{" "}
              </button>
            </div>
          </form>
          <div className="text-center fs-3 mt-3">
            {" "}
            Don’t have an account?
            <span onClick={signUpButtonClicked}>
              <a className="text-primary"> Sign Up </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
