import { useNavigate } from "react-router-dom";
import "./DentistDirectLandingPage.css";
import BrandLogo from "../../components/BrandLogo/BrandLogo";
import { useSelector } from "react-redux";
import smartScanImage from "./../../assets/images/Score_Carlos.png";

const DentistDirectLandingPage = () => {
  const navigate = useNavigate();
  const shouldShowSeeADentistNowBoxInLandingPage = useSelector(
    (state) => state?.appSettings?.settingsData?.isOnDemandCallFeatureEnabled
  );
  const shouldShowTeethWhitenessBoxInLandingPage = useSelector(
    (state) =>
      state?.appSettings?.settingsData?.shouldShowTeethWhitenessBoxInLandingPage
  );
  const shouldShowTheAiPhotoAssessmentVerbiageInLandingPageNavbar = useSelector(
    (state) =>
      state?.appSettings?.settingsData
        ?.shouldShowTheAiPhotoAssessmentVerbiageInLandingPageNavbar
  );

  function redirectToSignUpPage() {
    navigate({
      pathname: "/sign-up",
      search: window.location.search
    });
  }

  function redirectToSignInPage() {
    navigate({
      pathname: "/login",
      search: window.location.search
    });
  }

  function getSmartScanBoxVerbiage() {
    if (shouldShowTeethWhitenessBoxInLandingPage) {
      return "AI Dental Photo Assessment";
    }
    return "Free Smart Scan";
  }
  function getButtonVerbiage() {
    if (!shouldShowTheAiPhotoAssessmentVerbiageInLandingPageNavbar) {
      return "Start Your AI Photo Assessment";
    }
    return "Create a Free Account";
  }
  function getImageUrlForSmartScan() {
    if (shouldShowTheAiPhotoAssessmentVerbiageInLandingPageNavbar) {
      return "https://dental.com/hs-fs/hubfs/Dental/main/PhoneScore.png";
    }
    return smartScanImage;
  }

  return (
    <div className="ddd-registration">
      <div className="body-wrapper   hs-content-id-176066785621 hs-site-page page  type_transparent">
        <div className="container-fluid">
          <div className="row-fluid-wrapper">
            <div className="row-fluid">
              <div
                className="span12 widget-span widget-type-cell "
                style={{}}
                data-widget-type="cell"
                data-x={0}
                data-w={12}
              >
                <div className="row-fluid-wrapper row-depth-1 row-number-1 dnd_area_main_banner-row-0-force-full-width-section dnd-section dnd_area_main_banner-row-0-padding">
                  <div className="row-fluid ">
                    <div
                      className="span12 widget-span widget-type-cell dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={12}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_widget_1694450141631"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              {
                                <header className="header custom type_transparent">
                                  <div className="header__main-bar">
                                    <div className="header__container">
                                      <div className="header__logo__container no-logo-hover">
                                        <div className="header__logo logo1">
                                          <BrandLogo />
                                        </div>
                                      </div>
                                      <div className="header__menu">
                                        <input
                                          type="checkbox"
                                          id="nav-toggle"
                                        />
                                        <label
                                          className="header__navigation-toggle"
                                          htmlFor="nav-toggle"
                                        >
                                          <span className="header__mobile-menu-icon" />
                                        </label>
                                        <div className="header__navigation">
                                          <nav
                                            aria-label="Main menu"
                                            className="navigation-primary"
                                          >
                                            <span
                                              id="hs_cos_wrapper_widget_1694450141631_"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                              style={{}}
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="simple_menu"
                                            >
                                              <div
                                                id="hs_menu_wrapper_widget_1694450141631_"
                                                className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                                role="navigation"
                                                data-sitemap-name=""
                                                data-menu-id=""
                                                aria-label="Navigation Menu"
                                              >
                                                <ul role="menu">
                                                  {shouldShowSeeADentistNowBoxInLandingPage && (
                                                    <li
                                                      className="hs-menu-item hs-menu-depth-1"
                                                      role="none"
                                                    >
                                                      <a
                                                        onClick={
                                                          redirectToSignUpPage
                                                        }
                                                        role="menuitem"
                                                        target="_self"
                                                        className="landing-page-btn"
                                                      >
                                                        See a Dentist Now
                                                      </a>
                                                    </li>
                                                  )}
                                                  {shouldShowTheAiPhotoAssessmentVerbiageInLandingPageNavbar && (
                                                    <li
                                                      className="hs-menu-item hs-menu-depth-1"
                                                      role="none"
                                                    >
                                                      <a
                                                        onClick={
                                                          redirectToSignUpPage
                                                        }
                                                        role="menuitem"
                                                        target="_self"
                                                      >
                                                        AI Photo Assessment
                                                      </a>
                                                    </li>
                                                  )}
                                                  <li
                                                    className="hs-menu-item hs-menu-depth-1"
                                                    role="none"
                                                  >
                                                    <a
                                                      onClick={
                                                        redirectToSignInPage
                                                      }
                                                      role="menuitem"
                                                      target="_self"
                                                    >
                                                      Sign In
                                                    </a>
                                                  </li>
                                                  <li
                                                    className="hs-menu-item hs-menu-depth-1"
                                                    role="none"
                                                  >
                                                    <a
                                                      onClick={
                                                        redirectToSignUpPage
                                                      }
                                                      role="menuitem"
                                                      target="_self"
                                                    >
                                                      {getButtonVerbiage()}
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </span>
                                          </nav>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </header>
                              }
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
                <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-section">
                  <div className="row-fluid ">
                    <div
                      className="span12 widget-span widget-type-cell dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={12}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_widget_1709595942016"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div className="cb-cards-cm">
                                <div className="cb-cards-cm__next-card item-1">
                                  <div className="cb-cards-cm__next-card-intro">
                                    <h2>{getSmartScanBoxVerbiage()}</h2>
                                    <div className="cb-cards-cm__next-card-intro-text">
                                      <p>
                                        Follow the guides to take 5 photos of
                                        your teeth to receive a&nbsp;
                                        personalized oral health report and
                                        wellness score.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="cb-cards-cm__next-card-img">
                                    <img
                                      src={
                                        getImageUrlForSmartScan() +
                                        "?width=1487&height=2000&name=PhoneScore.png"
                                      }
                                      alt="PhoneScore"
                                      loading="lazy"
                                      width={1487}
                                      height={2000}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto"
                                      }}
                                      srcSet={
                                        getImageUrlForSmartScan() +
                                        "?width=744&height=1000&name=PhoneScore.png 744w, " +
                                        getImageUrlForSmartScan() +
                                        "?width=1487&height=2000&name=PhoneScore.png 1487w, " +
                                        getImageUrlForSmartScan() +
                                        "?width=2231&height=3000&name=PhoneScore.png 2231w," +
                                        getImageUrlForSmartScan() +
                                        "?width=2974&height=4000&name=PhoneScore.png 2974w, " +
                                        getImageUrlForSmartScan() +
                                        "?width=3718&height=5000&name=PhoneScore.png 3718w, " +
                                        getImageUrlForSmartScan() +
                                        "?width=4461&height=6000&name=PhoneScore.png 4461w"
                                      }
                                      sizes="(max-width: 1487px) 100vw, 1487px"
                                    />
                                  </div>
                                  <div className="button-w">
                                    <a
                                      className="cta_button"
                                      onClick={redirectToSignUpPage}
                                    >
                                      START YOUR SCAN
                                    </a>
                                  </div>
                                </div>
                                {shouldShowSeeADentistNowBoxInLandingPage && (
                                  <div className="cb-cards-cm__next-card item-2">
                                    <div className="cb-cards-cm__next-card-intro">
                                      <h2>See A Dentist Now</h2>
                                      <div className="cb-cards-cm__next-card-intro-text">
                                        <p>
                                          Speak with an online dentist 24/7 for
                                          issues including toothaches,
                                          infections, and dental emergencies.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="cb-cards-cm__next-card-img">
                                      <img
                                        src="https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=1000&height=1344&name=Consult.png"
                                        alt="Virtual Dental Consultation"
                                        loading="lazy"
                                        width={1000}
                                        height={1344}
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto"
                                        }}
                                        srcSet="https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=500&height=672&name=Consult.png 500w, https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=1000&height=1344&name=Consult.png 1000w, https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=1500&height=2016&name=Consult.png 1500w, https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=2000&height=2688&name=Consult.png 2000w, https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=2500&height=3360&name=Consult.png 2500w, https://dental.com/hs-fs/hubfs/Cigna/Consult.png?width=3000&height=4032&name=Consult.png 3000w"
                                        sizes="(max-width: 1000px) 100vw, 1000px"
                                      />
                                    </div>
                                    <div className="button-w">
                                      <a
                                        className="cta_button"
                                        onClick={redirectToSignUpPage}
                                      >
                                        SEE A DENTIST NOW
                                      </a>
                                    </div>
                                  </div>
                                )}
                                {shouldShowTeethWhitenessBoxInLandingPage && (
                                  <div className="cb-cards-cm__next-card item-2">
                                    <div className="cb-cards-cm__next-card-intro">
                                      <h2>Teeth Whiteness</h2>
                                      <div className="cb-cards-cm__next-card-intro-text">
                                        <p>
                                          Understand the current shade of your
                                          teeth and preview your smile with
                                          different teeth shades.
                                        </p>
                                      </div>
                                    </div>
                                    <div className="cb-cards-cm__next-card-img">
                                      <img
                                        src="https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=1487&height=2000&name=Consult.png"
                                        alt="Consult"
                                        loading="lazy"
                                        width={1487}
                                        height={2000}
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto"
                                        }}
                                        srcSet="https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=744&height=1000&name=Consult.png 744w, https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=1487&height=2000&name=Consult.png 1487w, https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=2231&height=3000&name=Consult.png 2231w, https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=2974&height=4000&name=Consult.png 2974w, https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=3718&height=5000&name=Consult.png 3718w, https://dental.com/hs-fs/hubfs/WhitenessScore.png?width=4461&height=6000&name=Consult.png 4461w"
                                        sizes="(max-width: 1487px) 100vw, 1487px"
                                      />
                                    </div>
                                    <div className="button-w">
                                      <a
                                        className="cta_button"
                                        onClick={redirectToSignUpPage}
                                      >
                                        Get your Whiteness Score
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
                <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-section dnd_area_main_banner-row-2-padding dnd_area_main_banner-row-2-force-full-width-section">
                  <div className="row-fluid ">
                    <div
                      className="span12 widget-span widget-type-cell dnd-column"
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={12}
                    >
                      <div className="row-fluid-wrapper row-depth-1 row-number-6 dnd-row">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_widget_1694455856171"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <footer className="footer custom">
                                <div className="footer__container">
                                  <div className="footer__top-section">
                                    <div className="row-fluid span12 group-wrapper">
                                      <div className="footer__info column span4">
                                        <div className="footer__logo">
                                          <BrandLogo />
                                        </div>
                                      </div>
                                      <div className="footer__menu span8">
                                        <div className="footer-menu-items">
                                          <div className="footer-menu-item">
                                            <span
                                              id="hs_cos_wrapper_widget_1694455856171_"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                              style={{}}
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="simple_menu"
                                            >
                                              <ul />
                                            </span>
                                          </div>
                                          <div className="footer-menu-item">
                                            <span
                                              id="hs_cos_wrapper_widget_1694455856171_"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                              style={{}}
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="simple_menu"
                                            >
                                              <ul />
                                            </span>
                                          </div>
                                          <div className="footer-menu-item">
                                            <span
                                              id="hs_cos_wrapper_widget_1694455856171_"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_simple_menu"
                                              style={{}}
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="simple_menu"
                                            >
                                              <ul />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="copyright-wrapper social span8">
                                    <p>Copyright 2024 Dental.com</p>
                                  </div>
                                </div>
                              </footer>
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                    </div>
                    {/*end widget-span */}
                  </div>
                  {/*end row*/}
                </div>
                {/*end row-wrapper */}
              </div>
              {/*end widget-span */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentistDirectLandingPage;
