import React from "react";
import FinishAccountSetupPage from "../../components/FinishAccoutSetup/FinishAccountSetupPage";

export default function FinishAccountSetup() {
  return (
    <body>
      <section className="account-section sign-in-section py-4 py-md-5 v-middle">
        <FinishAccountSetupPage />
      </section>
    </body>
  );
}
