import { Outlet, useLocation } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import BrandLogo from "../../components/BrandLogo/BrandLogo";
import { shouldShowBrandLogoInPublicRoute } from "../../helpers/utils";

const Layout = () => {
  const { pathname } = useLocation();
  return (
    <main className="App">
      <ScrollToTop />
      {shouldShowBrandLogoInPublicRoute(pathname) && (
        <header class="py-2">
          <nav class="navbar navbar-expand-lg p-0">
            <div class="container-fluid">
              <BrandLogo alignmentClass={"text-start"} />
            </div>
          </nav>
        </header>
      )}
      <Outlet />
    </main>
  );
};

export default Layout;
