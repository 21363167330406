import React from "react";
import PhoneNumberLoginPage from "../../components/Login/PhoneNumberLoginPage";

export default function PhoneNumberLogin() {
  return (
    <>
      <body>
        <section className="account-section sign-in-section py-4 py-md-5 v-middle min-vh-100">
          <PhoneNumberLoginPage />
        </section>
      </body>
    </>
  );
}
