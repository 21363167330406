import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGetSmartScanSelfieCaptureProgress from "../CustomHooks/useGetSmartScanSelfieCaptureProgress";
import { SmartScanCaptureOptions } from "../../constants";
import { saveSmartScanSelfieCaptureProgress } from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";

const SmartScanPhotoOptionPage = () => {
  const [option, setOption] = useState();
  const smartScanState = useGetSmartScanSelfieCaptureProgress();
  const navigate = useNavigate();

  useEffect(() => {
    if (smartScanState?.captureOption) {
      setOption(smartScanState.captureOption);
    }
  }, [smartScanState]);

  async function nextBtnClicked() {
    if (
      smartScanState &&
      smartScanState.patientId > 0 &&
      smartScanState.isAuthenticated
    ) {
      await saveSmartScanSelfieCaptureProgress(
        {
          ...smartScanState,
          captureOption: option
        },
        smartScanState?.patientId
      );

      navigate("/smart-scan-instruction", {
        state: {
          patientId: smartScanState?.patientId,
          captureOption: option
        }
      });
    } else if (
      smartScanState &&
      smartScanState.patientId &&
      !smartScanState.isAuthenticated
    ) {
      await saveSmartScanSelfieCaptureProgress(
        {
          ...smartScanState,
          captureOption: option
        },
        smartScanState?.patientId
      );

      navigate("/smart-scan-instruction-no-auth", {
        state: {
          patientId: smartScanState?.patientId,
          isAuthenticated: false,
          captureOption: option
        }
      });
    }
  }

  const onCaptureOptionChanged = (e) => {
    setOption(e.target.value);
  };

  function goToPreviousPage() {
    navigate("/smart-scan-disclaimer", {
      state: {
        ...smartScanState
      }
    });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="gth-main-header mb-3">
              <div className="heading-wrapper back-to-wrapper position-relative">
                <h1 className="title">How will you be taking the photos?</h1>
              </div>
            </div>
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="form-group mt-4 mb-3 col-sm-12">
                <div className="form-check mb-4 me-4 d-table">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="scan-option"
                    id="optionMyself"
                    defaultValue={SmartScanCaptureOptions.Myself}
                    checked={SmartScanCaptureOptions.Myself == option}
                    onChange={onCaptureOptionChanged}
                  />
                  <label
                    className="form-check-label align-items-center fs-4"
                    htmlFor="optionMyself"
                  >
                    <b className="me-2">Myself,</b> using my "selfie"
                    front-facing camera
                  </label>
                </div>
                <div className="form-check mb-4 me-4 d-table">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="scan-option"
                    id="optionAssisted"
                    defaultValue={SmartScanCaptureOptions.Assisted}
                    checked={SmartScanCaptureOptions.Assisted == option}
                    onChange={onCaptureOptionChanged}
                  />
                  <label
                    className="form-check-label align-items-center fs-4"
                    htmlFor="optionAssisted"
                  >
                    <b className="me-2">Assisted,</b> someone else will be
                    taking the photos{" "}
                    <span className="badge bg-primary btn-xs fs-5 fw-normal ms-sm-2 mt-2 mt-md-0 text-uppercase">
                      Recommended
                    </span>
                  </label>
                </div>
              </div>
              <div className="form-group text-center mt-2 mt-md-4 col-sm-12 button-wrapper">
                <button
                  onClick={nextBtnClicked}
                  className={`btn btn-secondary btn-rounded btn-lg px-5 mx-2 mt-3 mt-md-0 next-btn`}
                  disabled={!option}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartScanPhotoOptionPage;
