import { useDispatch, useSelector } from "react-redux";
import {
  saveSmartScanPhotos_Ajax,
  smartScanPostBookingProcedures_Ajax
} from "../../helpers/requests";
import { clearSmartScanSelfieCaptureProgress } from "../../indexedDbHelper/smartScanProgressIndexedDbHelper";
import { GuestPatientKey, smartScanCategory } from "../../constants";
import {
  arrayBufferToBlob,
  getSmartScanImageObjectNameForState,
  showAlertDialouge
} from "../../helpers/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatientInfo } from "../../helpers/authManager";
import useGetSmartScanSelfieCaptureProgress from "./useGetSmartScanSelfieCaptureProgress";
import { getIsSmartScanSelfieCaptureProgressCachingEnabled } from "../../ApplicationSettings";
import { useState } from "react";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";

export default function useSubmitSmartScan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSavingInProgress, setIsSavingInProgess] = useState(false);
  const smartScanState = useGetSmartScanSelfieCaptureProgress();
  const { pathname } = useLocation();
  const patientOrigin = useSelector(
    (state) => state?.appSettings?.settingsData?.patientOrigin
  );
  const pgId = useSelector((state) => state?.appSettings?.settingsData?.pgId);

  function getPatientId() {
    if (pathname == "/finish-account-setup") {
      return getPatientInfo().patid;
    } else if (smartScanState.patientId > 0) {
      return smartScanState.patientId;
    }
  }

  function getSmartScanCategoryPhotoFileObj(category) {
    if (category && smartScanState) {
      let imageFile;
      if (getIsSmartScanSelfieCaptureProgressCachingEnabled()) {
        imageFile = arrayBufferToBlob(
          smartScanState[getSmartScanImageObjectNameForState(category)]?.file,
          smartScanState[getSmartScanImageObjectNameForState(category)]?.name,
          smartScanState[getSmartScanImageObjectNameForState(category)]
            ?.mimeType
        );
      } else {
        imageFile =
          smartScanState[getSmartScanImageObjectNameForState(category)]?.file;
      }

      return imageFile;
    }
  }

  function smartScanPostBookingProcedures(postBookingProcedures) {
    if (postBookingProcedures) {
      smartScanPostBookingProcedures_Ajax(
        JSON.stringify(postBookingProcedures)
      );
    }
  }

  const submitSmartScan = () => {
    let bitePhoto = getSmartScanCategoryPhotoFileObj(smartScanCategory.Bite);
    let upperArchPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.UpperArch
    );
    let lowerArchPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.LowerArch
    );
    let upperLipPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.UpperLip
    );
    let lowerLipPhoto = getSmartScanCategoryPhotoFileObj(
      smartScanCategory.LowerLip
    );

    let patientId = getPatientId();

    if (
      smartScanState &&
      patientId > 0 &&
      bitePhoto &&
      lowerArchPhoto &&
      upperArchPhoto &&
      upperLipPhoto &&
      lowerLipPhoto
    ) {
      let formData = new FormData();
      let requestVM = {
        clientId: patientOrigin,
        pgId: pgId,
        patId: Number(patientId),
        createdBy: smartScanState.createdBy
      };

      formData.append("requestVM", JSON.stringify(requestVM));
      formData.append("bitePhoto", bitePhoto);
      formData.append("upperArchPhoto", upperArchPhoto);
      formData.append("lowerArchPhoto", lowerArchPhoto);
      formData.append("upperLipPhoto", upperLipPhoto);
      formData.append("lowerLipPhoto", lowerLipPhoto);

      dispatch(showLoadingSpinner());
      setIsSavingInProgess(true);

      saveSmartScanPhotos_Ajax(
        formData,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (
            response &&
            response.success &&
            response.data.smartScanId &&
            response.data.postBookingProcedures
          ) {
            smartScanPostBookingProcedures(response.data.postBookingProcedures);
            if (smartScanState.redirectUrl) {
              window.location.href = smartScanState.redirectUrl;
            } else {
              navigate("/smart-scan-submitted");
            }
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
          setIsSavingInProgess(false);
          clearSmartScanSelfieCaptureProgress(patientId);

          if (pathname == "/finish-account-setup") {
            clearSmartScanSelfieCaptureProgress(GuestPatientKey);
          }
        },
        function (error) {
          setIsSavingInProgess(false);
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
        }
      );
    }
  };

  return {
    submitSmartScan,
    isSavingInProgress,
    getSmartScanCategoryPhotoFileObj
  };
}
