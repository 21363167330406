import React from "react";
import SubmitVerificationCodePage from "../../components/SubmitVerificationCodePage/SubmitVerificationCodePage";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import { useDispatch } from "react-redux";
import useSendVerificationCode from "../../components/CustomHooks/useSendVerificationCode";
import useSubmitVerificationCode from "../../components/CustomHooks/useSubmitVerificationCode";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import BrandLogo from "../../components/BrandLogo/BrandLogo";

const SubmitVerificationCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { sendVerificationCode } = useSendVerificationCode();
  const { submitVerificationCode } = useSubmitVerificationCode();
  const dispatch = useDispatch();

  function onSubmit(code) {
    if (code && state.phoneNumber) {
      dispatch(showLoadingSpinner());
      submitVerificationCode(
        state.phoneNumber,
        code,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response.success) {
            navigate("/finish-account-setup", {
              state: { ...state }
            });
          } else if (response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (error) {
          handleApiErrors(error);
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function resendBtnClicked() {
    if (state.phoneNumber) {
      dispatch(showLoadingSpinner());
      sendVerificationCode(
        state.phoneNumber,
        function (response) {
          dispatch(hideLoadingSpinner());
          if (response.success) {
            showAlertDialouge(
              "Message",
              "A verification code has been re-sent to the number " +
                state.phoneNumber
            );
          } else if (response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (error) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(error);
        }
      );
    }
  }

  return (
    <body>
      <section className="account-section sign-in-section py-4 py-md-5 v-middle">
        <div className="container">
          <BrandLogo />
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xxl-4 offset-xxl-4">
              <SubmitVerificationCodePage
                phoneNumber={state.phoneNumber}
                numDigits={6}
                onSubmit={onSubmit}
                onResend={resendBtnClicked}
              />
            </div>
          </div>
        </div>
      </section>
    </body>
  );
};

export default SubmitVerificationCode;
