import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SmartScanWorksPage from "../../components/SmartScanWorks/SmartScanWorksPage";
import { PATIENT_PORTAL_AUTO_LOGIN_API_KEY } from "../../constants";
import { getTokenData } from "../../helpers/authManager";
import { getQRCode_ajax } from "../../helpers/requests";
import { handleApiErrors, showAlertDialouge } from "../../helpers/utils";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { getBaseUrl } from "../../ApplicationSettings";
import { useLocation, useNavigate } from "react-router-dom";

const SmartScanWorks = () => {
  const [QRCode, setQRCode] = useState();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAutheticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  function checkIfLoggedIn() {
    let tokenData = getTokenData();

    if (tokenData?.token) {
      if (location.pathname == "/smart-scan-works-no-auth") {
        navigate("/smart-scan-works");
      }

      setIsAutheticated(true);
    } else {
      setIsAutheticated(false);
    }
  }

  useEffect(() => {
    getQRCode();
  }, [isAuthenticated]);

  function getQRCode() {
    let url = "";
    if (isAuthenticated) {
      let tokenData = getTokenData();
      if (tokenData && tokenData.token) {
        url = encodeURIComponent(
          getBaseUrl() +
            "/login?apiKey=" +
            PATIENT_PORTAL_AUTO_LOGIN_API_KEY +
            "&token=" +
            tokenData.token +
            "&redirectTo=/smart-scan-works"
        );
      }
    } else {
      url = encodeURIComponent(getBaseUrl() + "/smart-scan-works-no-auth");
    }

    dispatch(showLoadingSpinner());
    getQRCode_ajax(
      url,
      function (response) {
        dispatch(hideLoadingSpinner());
        if (response && response.success && response.data) {
          setQRCode(response.data);
        } else if (response && !response.success && response.message) {
          showAlertDialouge("Error", response.message);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            <SmartScanWorksPage
              isAuthenticated={isAuthenticated}
              QRCode={QRCode}
              setQRCode={setQRCode}
            />
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanWorks;
