import React from "react";
import SmartScanSelfieConfirm from "../../components/SmartScanSelfieConfirmPage/SmartScanSelfieConfirm";
import { smartScanCategory } from "../../constants";
import useGetSmartScanSelfieCaptureProgress from "../../components/CustomHooks/useGetSmartScanSelfieCaptureProgress";

const SmartScanLowerLipConfirm = () => {
  const smartScanState = useGetSmartScanSelfieCaptureProgress();

  return (
    <>
      <body className="mobile-body">
        <div className="body-content">
          <section className="review-photo-section py-4 py-md-5">
            {smartScanState ? (
              <SmartScanSelfieConfirm
                nextPagePath={
                  smartScanState.isAuthenticated
                    ? "/smart-scan-bite"
                    : "/smart-scan-bite-no-auth"
                }
                retakePagePath={
                  smartScanState.isAuthenticated
                    ? "/smart-scan-lower-lip"
                    : "/smart-scan-lower-lip-no-auth"
                }
                state={smartScanState}
                category={smartScanCategory.LowerLip}
              />
            ) : (
              <></>
            )}
          </section>
        </div>
      </body>
    </>
  );
};

export default SmartScanLowerLipConfirm;
